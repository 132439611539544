import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { env } from "../../env.mjs";
import RedirectIdpPage from "../../pages/auth/RedirectIdp";
import SetupPage from "../../pages/auth/Setup";
import LoginPage from "../../pages/auth/native/Login";
import { MobileAuthenticationPage } from "../../pages/auth/native/MobileAuthentication";
import { NotUpdatedPage } from "../../pages/error/NotUpdated";
import { useCapacitorProvider } from "../capacitor/CapacitorProvider";
import { addPushNotificationListeners } from "../capacitor/PushNotificationService";
import { useAuthProvider } from "./AuthProvider";

export function isPublicPage() {
    const publicPages = [
        "/callback",
        "/redirect-idp",
        "/authenticator/",
        "/mobile",
        "/update-account",
        "/login-link",
        "/welkom",
        "/redirect",
        "/app",
        "/medewerker-link",
        "/dev/welkom",
        "/brand/inloggen",
        "/public/club/quiz",
        "/user-group-link",
        "/add-pinned-shortcut",
        "/brand-landing",
    ];
    const currentPath = window.location.pathname;

    return publicPages.includes(currentPath) || publicPages.some((publicPath) => currentPath.startsWith(publicPath));
}

type Props = {
    children: JSX.Element;
};

export default function PrivateRouter(props: Props) {
    const { children } = props;
    const { authBioVerified, authLoading, authBody } = useAuthProvider();
    const { authPreferences, capLoading, isLatestAppVersion } = useCapacitorProvider();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authBody) return;

        addPushNotificationListeners(navigate, authBody);
    }, [authBody]);

    if (authLoading || capLoading) {
        return null;
    }

    // not latest app version, please go to store
    if (!["development"].includes(env.devEnv) && !isLatestAppVersion) {
        return <NotUpdatedPage />;
    }

    // authBody not found on private page, login again
    if (!authBody && !isPublicPage()) {
        if (Capacitor.isNativePlatform()) {
            return <LoginPage />;
        }
        return <RedirectIdpPage />;
    }

    if (Capacitor.isNativePlatform() && !isPublicPage()) {
        // before bio/pin authentication, setup device
        if (authPreferences === null) return <SetupPage />;
        else if (!authBioVerified) {
            // mobile app has to verify biometrics/pincode
            return <MobileAuthenticationPage />;
        }
    }

    return children;
}
