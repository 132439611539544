import { useClubProvider } from "../../service/ClubService";
import { BrandResponse, useBrandById } from "../../service/api/brandService";
import { Avatar, Skeleton, useTheme, Typography, Button, Box } from "@mui/material";
import { useCouponById } from "../../service/api/couponService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useBrandMediaContentProvider } from "../../service/mediaContentService";
import { env } from "../../env.mjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CouponDialog } from "./CouponDialog";
import { useTranslation } from "react-i18next";
import { logEventWithCouponId } from "../../service/AnalyticsService";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";

export const PartnerHighlight = ({
    detailed,
    brand_id,
    coupon_id,
    logo_background,
}: {
    detailed: boolean;
    brand_id: string;
    coupon_id: string;
    logo_background?: string;
}) => {
    const theme = useTheme();

    const { data: partner } = useBrandById(brand_id, { suspense: detailed });

    return (
        <div
            style={{
                border: !detailed ? "1px solid #ffffff" : "",
                padding: theme.spacing(1),
            }}
        >
            {partner ? (
                detailed ? (
                    <DetailedHeader {...partner} logo_background={logo_background} />
                ) : (
                    <SimpleHeader name={partner.name} partner_id={partner.id} />
                )
            ) : (
                <HeaderSkeleton />
            )}
            {partner ? <Image brand_id={partner.id} detailed={detailed} /> : <ImageSkeleton />}
            <CouponPreview isLoading={!partner} coupon_id={coupon_id} suspense={detailed} />
        </div>
    );
};

const Image = ({ brand_id, detailed }: { brand_id: string; detailed: boolean }) => {
    const theme = useTheme();

    const { GetBrandMediaContent } = useBrandMediaContentProvider();

    const hero = GetBrandMediaContent({
        brandId: brand_id,
        directory: "hero",
    });

    return (
        <div
            style={{
                position: "relative",
                marginBottom: theme.spacing(2),
            }}
        >
            <img
                src={hero?.Url}
                style={{
                    width: "100%",
                    verticalAlign: "middle",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    border: detailed ? "1px solid #ffffff" : "",
                    top: 8,
                    bottom: 8,
                    right: -8,
                    left: -8,
                    zIndex: -1,
                }}
            />
        </div>
    );
};

const SimpleHeader = ({ name, partner_id }: { name: string; partner_id: string; logo_background?: string }) => {
    const { brand_config, brand_id } = useClubProvider();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: "flex",
                margin: "8px 0px",
                fontSize: "20px",
                alignItems: "center",
            }}
        >
            {name.toUpperCase()}
            <div
                style={{
                    fontSize: "16px",
                    lineHeight: "19px",
                    marginLeft: "auto",
                    height: "fit-content",
                    alignItems: "center",
                    fontFamily: brand_config.environment?.fonts.secondary,
                }}
                onClick={() => {
                    navigate(`/club/partners/${brand_id}/partner/${partner_id}`);
                }}
            >
                {t("club.coupon.see").toUpperCase()} {name.split(" ")[0].toUpperCase()}
                <FontAwesomeIcon
                    style={{
                        marginLeft: "4px",
                    }}
                    icon={faAngleRight}
                />
            </div>
        </div>
    );
};

const DetailedHeader = (props: BrandResponse & { logo_background?: string }) => {
    const { brand_config, brand_id } = useClubProvider();
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const { GetBrandMediaContent: GetMediaContent } = useBrandMediaContentProvider();

    const image = GetMediaContent({
        brandId: props.id,
        directory: "icon",
        metadata: {
            type: ["image"],
        },
    });

    return (
        <div
            style={{
                display: "flex",
                margin: "4px 0px",
                justifyContent: "space-between",
            }}
        >
            <div
                style={{
                    fontSize: "20px",
                    display: "flex",
                    marginBottom: theme.spacing(2),
                }}
            >
                {image ? (
                    <BrandIcon
                        brandId={props.id}
                        sx={{
                            height: 50,
                            width: 50,
                        }}
                    />
                ) : (
                    <Skeleton variant="circular" height={50} width={50} />
                )}
                <div
                    style={{
                        marginLeft: "8px",
                    }}
                >
                    <div>{props.name.toUpperCase()}</div>
                    <div
                        style={{
                            fontSize: "12px",
                            fontFamily: brand_config.environment?.fonts.secondary,
                        }}
                    >
                        {props.tags?.join(" | ").toLowerCase()}
                    </div>
                </div>
            </div>
            <Button
                variant="text"
                size="small"
                endIcon={<FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "1em" }} />}
                onClick={() => {
                    navigate(`/club/partners/${brand_id}/partner/${props.id}`);
                }}
            >
                {t("club.coupon.more")}
            </Button>
        </div>
    );
};

const HeaderSkeleton = () => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <Skeleton
                variant="circular"
                height={50}
                width={50}
                sx={{
                    aspectRatio: "1 / 1",
                }}
            />
            <div
                style={{
                    width: "100%",
                    marginLeft: "8px",
                }}
            >
                <Skeleton height={30} width={"100%"} />
                <Skeleton height={20} width={"100%"} />
            </div>
        </div>
    );
};

const ImageSkeleton = () => {
    return <Skeleton height={200} width={"100%"} sx={{ transform: "none", transformOrigin: "0", mb: 2 }} />;
};

const CouponPreview = ({
    isLoading,
    coupon_id,
    suspense = true,
}: {
    isLoading: boolean;
    coupon_id: string;
    suspense?: boolean;
}) => {
    const { brand_id } = useClubProvider();
    const { t } = useTranslation();
    const theme = useTheme();

    const { data: coupon } = useCouponById(coupon_id, { suspense });
    const [open, setOpen] = useState(false);

    if (!coupon || isLoading) return <CouponSkeleton />;

    return (
        <div
            style={{
                marginTop: "4px",
                display: "flex",
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: "140px",
                }}
            >
                <ImageWithFallback
                    src={coupon.image}
                    fallBackUrl={coupon.image?.replace(/\.webp$/, ".jpg")}
                    width={"100%"}
                    height="115px"
                    containerSx={{
                        height: "100%",
                    }}
                    objectFit="contain"
                    type="rectangular"
                />
            </div>

            <Box
                sx={{
                    marginLeft: theme.spacing(1),
                    display: "flex",
                    flexDirection: "column",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "13px",
                    },
                }}
            >
                <Typography variant="h4">{coupon.name?.toUpperCase()}</Typography>
                <Typography variant="body1" mb={1} style={{ opacity: 0.7 }}>
                    {coupon.description}
                </Typography>
                <div
                    style={{
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "1em" }} />}
                        onClick={() => {
                            logEventWithCouponId("coupon_view", coupon.id);
                            setOpen(true);
                        }}
                    >
                        {t("club.coupon.go").toUpperCase()}
                    </Button>
                </div>
                <CouponDialog
                    brandId={brand_id}
                    coupon={coupon}
                    dialog={{
                        open: open,
                        onClose: () => {
                            logEventWithCouponId("coupon_close", coupon.id);
                            setOpen(false);
                        },
                    }}
                />
            </Box>
        </div>
    );
};

const CouponSkeleton = () => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <Skeleton variant="rounded" height={115} width={150} />
            <div
                style={{
                    width: "60%",
                    margin: "0 4px",
                }}
            >
                <Skeleton
                    variant="rounded"
                    height={20}
                    style={{
                        margin: "4px 0",
                    }}
                />
                <Skeleton
                    variant="rounded"
                    height={50}
                    style={{
                        margin: "4px 0",
                    }}
                />
                <Skeleton
                    variant="rounded"
                    height={18}
                    style={{
                        margin: "4px 0",
                    }}
                />
            </div>
        </div>
    );
};
