import { z } from "zod";
import { TranslatableConfigTextSchema } from "../ConfigTranslator";

const ColorSchema = z.object({
    primary: z.string().optional(),
    primaryText: z.string().optional(),

    secondary: z.string().optional(),
    secondaryText: z.string().optional(),

    tertiary: z.string().optional(),
    tertiaryText: z.string().optional(),

    background: z.string().optional(),
    logoBackground: z.string().optional(),
    gradient: z.string().optional(),

    skeleton: z.string().optional(),

    error: z.string().optional(),
});

const SocialMediaSchema = z.record(z.string());

const BrandTypeSchema = z
    .union([z.literal("brand"), z.literal("sport-club"), z.literal("competition"), z.literal("bso")])
    .optional();

export const UpdatedBrandConfigSchema = z.object({
    _id: z.string(),
    name: z.string(),
    type: BrandTypeSchema,

    socialMedia: SocialMediaSchema.optional(),
    colors: ColorSchema,

    onboarding: z.object({
        landing: z.object({
            colors: z.object({
                text: z.string(),
                pageBackground: z.string().optional(),
                heroBackground: z.string().optional(),
                heroGradient: z.string().optional(),
                dividerBackground: z.string().optional(),
                bannerBackground: z.string().optional(),
                bannerText: z.string().optional(),
                stepsBackground: z.string().optional(),
            }),
            hasDivider: z.boolean().optional(),
            hasBackgroundTint: z.boolean().optional(),
            greetingText: TranslatableConfigTextSchema.optional(),
            descriptionText: TranslatableConfigTextSchema.optional(),
            benefitDescriptionText: TranslatableConfigTextSchema.optional()
        }).optional(),
    }),
});

export type UpdatedBrandConfig = z.infer<typeof UpdatedBrandConfigSchema>;
